.certifications {
  padding: 50px 0;
}

.certifications .certifications-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.certification {
  background-color: var(--background-color);
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--certificates-box-shadow);
  transition: all var(--transition);
}

.certification:hover {
  transform: translateY(-5px);
}

.certification-img {
  margin-bottom: 5px;
  overflow: hidden;
  height: 300px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.certification-img a {
  display: block;
}

.certification-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.certification .certification-title {
  text-align: center;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 10px;
}

.certification .certification-info {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}
