/* ThemeToggle/ThemeToggle.css */
.icon-container {
  position: fixed;
  top: 50%;
  right: 0px;
  z-index: 999;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--orange);
  cursor: pointer;
}

.sun-icon,
.dark-mode-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: opacity 0.3s, transform 0.3s; /* Add transitions */
}

.sun-icon.visible,
.dark-mode-icon.visible {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.dark-mode-icon,
.sun-icon {
  color: var(--orange);
}
