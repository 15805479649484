.contact {
  background-color: var(--section-color);
  padding: 50px 0;
}

.contact .form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
}

.contact .form-container .contact-form {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  border: var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact .contact-form .form-group {
  display: flex;
  gap: 20px;
  position: relative;
}

.contact .contact-form .form-group div {
  position: relative;
  flex-grow: 1;
}

.contact .form-group input,
.contact .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #242d49 !important;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  color: var(--input-text-color);
  position: relative;
}

.contact .form-group label {
  color: var(--gray);
  font-size: 1rem;
  font-weight: 500;
  position: absolute;
  left: 0;
  padding: 10px;
  transition: var(--transition);
}

.contact .form-group input:valid + label,
.contact .form-group textarea:valid + label,
.contact .form-group input:focus + label,
.contact .form-group textarea:focus + label,
.has-data {
  transform: translate(0px, -20px) !important;
  font-size: 12px !important;
  border-left: var(--border-color);
  border-right: var(--border-color);
  padding: 0px 5px !important;
  left: 10px !important;
  top: 10px !important;
  background-color: var(--label-background-color);
}

.contact .form-group input:focus,
.contact .form-group textarea:focus {
  transition: var(--transition) transform, var(--transition) border-color,
    var(--transition) background;
  border-bottom: var(--input-border-bottom-color) !important;
}

.contact .form-group textarea {
  min-height: 120px !important;
}

.contact .btn-submit {
  background-color: #242d49;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--transition);
  align-self: flex-start;
}

.contact .btn-submit:hover {
  background-color: #2c344e;
}

#message::-webkit-scrollbar {
  width: 12px;
}

#message::-webkit-scrollbar-track {
  background: var(--textarea-scrollbar-track);
}

#message::-webkit-scrollbar-thumb {
  background: var(--textarea-scrollbar-thumb);
  border-radius: 2px;
}

#message::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#message::-webkit-resizer {
  background-color: #888;
}

.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: var(--orange);
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.alert.success {
  opacity: 1;
}
