/* */

.title {
  position: relative;
  text-transform: uppercase;
  overflow: hidden;
  transition: var(--transition);
  color: var(--text-color);
  font-weight: 600;
  font-size: 30px;
  margin: 50px auto;
  text-align: center;
  width: fit-content;
  padding: 10px 30px;
  cursor: pointer;
  letter-spacing: 3px;
}
@media (max-width: 767px) {
  .title {
    font-size: 30px;
    word-wrap: normal;
  }
}
.title:hover {
  color: white;
  background: var(--sectionHeader-color);
  box-shadow: 0 0 10px var(--sectionHeader-color),
    0 0 40px var(--sectionHeader-color), 0 0 80px var(--sectionHeader-color);
  transition-delay: 1s;
}
.title span {
  position: absolute;
  display: block;
}
.title span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--sectionHeader-color));
}
.title:hover span:nth-child(1) {
  left: 100%;
  transition: 1s;
}

.title span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, var(--sectionHeader-color));
}
.title:hover span:nth-child(3) {
  right: 100%;
  transition: 1s;
  transition-delay: 0.5s;
}

.title span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--sectionHeader-color));
}
.title:hover span:nth-child(2) {
  top: 100%;
  transition: 1s;
  transition-delay: 0.25s;
}

.title span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--sectionHeader-color));
}
.title:hover span:nth-child(4) {
  bottom: 100%;
  transition: 1s;
  transition-delay: 0.75s;
}
